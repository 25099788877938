"use server";

import { getRequestEvent } from "solid-js/web";
import { ClientConfig } from "../types/client";
import { UserProfile } from "../types/user";
import {
  getCategories,
  getClientConfig,
  getCoinsSummary,
  getProductSearch,
  getUserProfile,
} from "../apis/server_apis";
import { SDKInitParams } from "../types/auth";
import { SearchResults } from "../types/search";
import { CategoryResult } from "../types/category";
import { Mode } from "~/types";
import { CoinsSummaryResponse } from "../types/order";
import { getMarkdown } from "../static";
import { Metadata } from "../types/groups";

export type CategoryRouteData = {
  brandList: SearchResults;
  categoryList: CategoryResult;
  clientConfig?: ClientConfig;
  userProfile?: UserProfile;
  metadata?: Metadata;
  sdkMetaData?: SDKMetaData;
  isLoggedIn: boolean;
  mode: Mode;
  coinsSummary?: CoinsSummaryResponse;
  clientId?: string;
};

type SDKMetaData = {
  isSDK: boolean;
  sid: string | undefined;
};

export const getCategoryRouteData = async (
  category: string,
  sdkInitParams: Partial<SDKInitParams>
): Promise<CategoryRouteData> => {
  let clientConfig$P: Promise<ClientConfig> | undefined;
  let userProfile$P: Promise<UserProfile> | undefined;
  let coinsSummary$P: Promise<CoinsSummaryResponse> | undefined;

  const requestEvent = getRequestEvent();
  const mode = requestEvent?.locals.mode ?? "hubble";

  if (sdkInitParams.clientId) {
    clientConfig$P = getClientConfig(sdkInitParams.clientId);
    userProfile$P = getUserProfile();
  } else if (mode === "rnr" && requestEvent?.locals.clientId) {
    clientConfig$P = getClientConfig(requestEvent.locals.clientId);
    coinsSummary$P = getCoinsSummary();
  } else if (getRequestEvent()?.locals.sid !== undefined) {
    coinsSummary$P = getCoinsSummary();
  }

  const brandList$P = getProductSearch({ category: category });
  const categories$P = getCategories();
  const metadata$P = getMarkdown(`webstore/categories/${category}.json`);

  const resolved = await Promise.all([
    brandList$P,
    categories$P,
    userProfile$P ?? Promise.resolve(undefined),
    clientConfig$P ?? Promise.resolve(undefined),
    coinsSummary$P ?? Promise.resolve(undefined),
    metadata$P ?? Promise.resolve(undefined),
  ]);

  const brandList = resolved[0];
  const categoryList = resolved[1];
  const userProfile = resolved[2];
  const clientConfig = resolved[3];
  const coinsSummary = resolved[4];
  const metadataDetails = resolved[5];

  let routeData = {
    brandList,
    categoryList,
    userProfile,
    clientConfig,
    isLoggedIn: getRequestEvent()?.locals.sid !== undefined,
    mode: mode,
    clientId: requestEvent?.locals?.clientId,
    coinsSummary: coinsSummary,
    metadata: metadataDetails?.metadata,
  };

  return routeData;
};
